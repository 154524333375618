// COLORS
$primary-color-00: #569FAE;
$primary-color-01: #4eaebf;
$primary-color-02: #84d8e8;
$primary-color-025: #ddf3f8;
$primary-color-03: #e7f7fa;
$primary-color-04: #e5e5e5;
$primary-color-05: #f3f7f9;

$success-color-01: #6CD4AF;

$secondary-color-00: #48555e;
$secondary-color-01: #2b3033;
$secondary-color-02: #8c979f;
$secondary-color-03: #dfe3e5;
$secondary-color-04: #ebeef0;
$secondary-color-05: #3D4B54;

$dark-blue-color-00: #2374E1;
$dark-blue-color-01: #3b82de;
$dark-blue-color-02: #579af1;

$stripe-border: #E4E8ED;
$stripe-background: #F7FAFC;

$neutral-white: #ffffff;
$neutral-gray: #fafafa;
$neutral-light-background: #f8f8f8;
$dark-gray: #7993A6;
$notification: #FE3D2F;
$error-bride: #ea4335;
$error-hover: #d62f21;
$error-active: #fe5749;
$error-background: rgba(234, 67, 53, 0.1);

$brand-background: #f3f7f9;
$yellow-color: #fbbc05;
$gold-color: #efc148;
$yellow-background: rgba(251, 188, 5, 0.1);
$white-hover: rgba(255, 255, 255, 0.08);
$white-disabled: rgba(255, 255, 255, 0.4);
$white-transparent: rgba(255, 255, 255, 0.5);

$slider-cover: rgba(0, 0, 0, 0.4);

// FONTS
$base-font-size: 16px;
$base-line-height: 24px;

// MEDIA
$extra-desktop-width: 1440px;
$desktop-width: 1270px;
$under-desktop-width: 940px;
$above-tablets-width: 815px;
$tablets-width: 768px;
$under-tablet-width: 566px;
$above-mobiles-width: 480px;
$mobiles-width: 460px;
$under-mobiles-width: 360px;

// EFFECTS
$small-item-transition: 0.2s;
